import axios from 'axios';

export const API_URL = process.env.REACT_APP_SERVER;

const $http = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_SERVER,
});

$http.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

$http.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER}auth/refresh`,
          {
            withCredentials: true,
          }
        );
        localStorage.setItem('token', response.data.token.accessToken);
        return $http.request(originalRequest);
      } catch (e) {
        console.log('НЕ АВТОРИЗОВАН');
      }
    }
    throw error;
  }
);

export default $http;
