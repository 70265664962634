import $http from '@http';

export default class AuthService {
  static async loginByVK(token) {
    return $http.post('/auth/login/vk', { token });
  }

  static async logout() {
    return $http.post('/auth/logout');
  }
}
