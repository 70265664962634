import { makeAutoObservable, toJS } from 'mobx';
import AuthService from '@services/Auth';
import axios from 'axios';

class AuthStore {
  /*eslint-disable */
  user = {};
  isAuth = false;
  isLoading = false;
  /*eslint-enable */

  constructor() {
    makeAutoObservable(this);
  }

  setAuth (bool) {
    this.isAuth = bool;
  }

  setUser (user) {
    this.user = user;
  }

  setRegionID (regionID) {
    this.user.regionID = regionID;
  }

  setLoading (bool) {
    this.isLoading = bool;
  }

  getUser () {
    return toJS(this.user);
  }

  async login (token) {
    console.log('login');
    try {
      const response = await AuthService.loginByVK(token);
      console.log('LOGIN');
      console.log(response);
      localStorage.setItem('token', response.data.token.accessToken);
      localStorage.setItem('userID', response.data.user.userID);
      localStorage.setItem('status', response.data.user.status);
      if (response.data.user.allowedRegions.length < 1) {
        response.data.user.allowedRegions = null;
      }
      localStorage.setItem(
        'allowedRegions',
        JSON.stringify(response.data.user.allowedRegions)
      );
      /*       localStorage.setItem('fullName', response.data.user.fullName);
      localStorage.setItem('regionID', response.data.user.regionID); */

      this.setAuth(true);
      this.setUser(response.data.user);
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  }

  async logout () {
    try {
      await AuthService.logout();
      localStorage.clear();
      this.setAuth(false);
      this.setUser({});
      window.location = '/main';
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  }

  async checkAuth () {
    this.setLoading(true);
    try {
      console.log(`${process.env.REACT_APP_SERVER}auth/refresh`);
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}auth/refresh`,
        {
          withCredentials: true,
        }
      );
      console.log('AUTH');
      console.log(response);
      localStorage.setItem('token', response.data.token.accessToken);
      localStorage.setItem('userID', response.data.user.userID);
      localStorage.setItem('status', response.data.user.status);
      if (
        !response.data.user.allowedRegions ||
        response.data.user.allowedRegions.length < 1
      ) {
        response.data.user.allowedRegions = null;
      }

      localStorage.setItem(
        'allowedRegions',
        JSON.stringify(response.data.user.allowedRegions)
      );

      this.setAuth(true);
      this.setUser(response.data.user);
    } catch (e) {
      console.log(e);
      console.log(e.response?.data?.message);
    } finally {
      this.setLoading(false);
    }
  }
}

export default AuthStore;
